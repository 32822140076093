<template>
  <v-card color="transparent" class="pt-0 elevation-0 expande-horizontal wrap">
    <div class="expande-horizontal wrap pb-0">
      <v-flex xs12 md6>
        <v-flex xs12>
          <v-form ref="form">
            <v-timeline align-top dense>
              <v-timeline-item icon="mdi-account-circle">
                <v-list-item class="pt-0 ma-0">
                  <v-list-item-content>
                    <v-list-item-title class="fonte blue--text">
                      Escolha uma foto
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Click no botão "Add" e escolha uma foto para o seu perfil!
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex xs12 class="px-1">
                  <ModalSendArchive :unique="true" />
                </v-flex>
              </v-timeline-item>

              <v-timeline-item icon="mdi-account-details-outline">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="fonte blue--text">
                      Preencha seus dados
                    </v-list-item-title>
                    <v-list-item-subtitle class="fonte grey--text">
                      Veja abaixo seus dados na plataforma
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-flex class="px-3" xs12>
                  <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                    Nome
                  </span>
                  <v-text-field
                    tile
                    v-model="getLoggedUser.nome"
                    dense
                    solo-inverted
                    flat
                    clearable
                    :color="$theme.primary"
                    :rules="[v => !!v || 'Preencha este campo']"
                    label="ex: Roberto"
                  ></v-text-field>
                </v-flex>

                <v-flex class="px-3" xs12>
                  <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                    Email
                  </span>
                  <v-text-field
                    v-model="getLoggedUser.email"
                    dense
                    readonly
                    solo-inverted
                    flat
                    clearable
                    :color="$theme.primary"
                    :rules="[v => !!v || 'Preencha este campo']"
                    label="ex: meuemail@gmail.com"
                  ></v-text-field>
                </v-flex>

                <div class="expande-horizontal wrap">
                  <v-flex class="px-3" xs12 md6>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      CPF
                    </span>
                    <v-text-field
                      v-model="getLoggedUser.cpf"
                      dense
                      solo-inverted
                      flat
                      readonly
                      :rules="[v => !!v || 'Preencha este campo']"
                      v-mask="['###.###.###-##']"
                      :color="$theme.primary"
                      label="ex: 000.000.000-00"
                    ></v-text-field>
                  </v-flex>

                  <v-flex class="px-3" xs12 md6>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      RG
                    </span>
                    <v-text-field
                      v-model="getLoggedUser.rg"
                      dense
                      solo-inverted
                      readonly
                      flat
                      :rules="[v => !!v || 'Preencha este campo']"
                      v-mask="['#########']"
                      :color="$theme.primary"
                      label="ex: 000.000"
                    ></v-text-field>
                  </v-flex>
                </div>

                <div class="expande-horizontal wrap">
                  <v-flex class="px-3" xs12 md6>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      Senha
                    </span>
                    <v-text-field
                      v-model="getLoggedUser.senha"
                      dense
                      solo-inverted
                      flat
                      type="password"
                      clearable
                      :color="$theme.primary"
                      label="******"
                    ></v-text-field>
                  </v-flex>

                  <v-flex class="px-3" xs12 md6>
                    <span
                      class="py-3 fonte"
                      :style="`color: ${$theme.primary}`"
                    >
                      Telefone
                    </span>
                    <v-text-field
                      v-model="getLoggedUser.phone"
                      dense
                      solo-inverted
                      flat
                      :rules="[v => !!v || 'Preencha este campo']"
                      v-mask="['(##) # #### ####']"
                      clearable
                      :color="$theme.primary"
                      label="ex: (96) 9 8419-6827"
                    ></v-text-field>
                  </v-flex>
                </div>
                <v-flex class="px-3" xs12 md6>
                  <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                    Nascimento
                  </span>
                  <v-text-field
                    v-model="getLoggedUser.data_nascimento"
                    dense
                    solo-inverted
                    flat
                    :rules="[v => !!v || 'Preencha este campo']"
                    v-mask="['##/##/####']"
                    clearable
                    :color="$theme.primary"
                    label="ex: 12/12/1984"
                  ></v-text-field>
                </v-flex>
              </v-timeline-item>
            </v-timeline>
          </v-form>
        </v-flex>
        <div class="expande-horizontal px-6">
          <v-btn dark @click="valida_form" color="green">
            <span class="fonte"> Salvar </span>
          </v-btn>
        </div>
      </v-flex>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters(["getLoggedUser", "getLinks"])
  },
  methods: {
    ...mapActions(["atualizar_funcionario", "fecha_modal_view_funcionario"]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.getLoggedUser._id
          ? this.atualizar_funcionario()
          : this.criar_funcionario();
      }
    }
  },
  created() {
    this.$store.commit("setLinks", this.getLoggedUser.photo);
    this.getLoggedUser.senha = undefined;
  }
};
</script>
